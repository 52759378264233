import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
import { Box } from "theme-ui";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Obaly a sáčky - rychle a kvalitně" description="Vyrábíme plastové sáčky a pytle, rychle, kvalitně, na míru a v počtech od 1000 kusů." mdxType="SEO" />
    <h1 {...{
      "id": "vyrábíme-obaly-a-sáčky"
    }}>{`Vyrábíme obaly a sáčky`}</h1>
    <hr></hr>
    <Box p={3} color="primary" bg="muted" mdxType="Box">
  Rychle, kvalitně, na míru a v počtech od 1000 kusů.
    </Box>
    <p>{`Vážení obchodní přátelé,
rád bych Vám představil naši společnost `}<strong parentName="p">{`R & K spol. s r.o. rychle a kvalitně`}</strong>{`, zabývající se výrobou `}<a parentName="p" {...{
        "href": "/technologie/ukazky/sacky-a-pytle/"
      }}>{`sáčků, obalů a obalového materiálu`}</a>{`.`}</p>
    <p>{`Na českém trhu `}<strong parentName="p">{`působíme již od roku 1992`}</strong>{`. Za tuto dobu jsme získali mnoho cenných zkušeností, které využíváme k Vaší plné spokojenosti.`}</p>
    <p>{`Vycházíme vstříc menším firmám a začínajícím podnikatelům, pro něž `}<strong parentName="p">{`vyrábíme v sériích již od 1000 ks`}</strong>{`. Vážíme si zavedených velkoodběratelů, proto svým partnerům poskytujeme výrazné množstevní slevy.`}</p>
    <p>{`Víme, že obal prodává, proto veškerou snahu soustředíme na stálé zdokonalování našich výrobků. Ať už si objednáte cokoliv z naší nabídky, máte záruku nejkratšího možného termínu dodání, vysoké kvality, a to při ceně která se Vám bude líbit.`}</p>
    <p>{`Všem našim stávajícím zákazníkům děkujeme za jejich přízeň a s novými se těšíme na brzkou spolupráci.`}</p>
    <p><a parentName="p" {...{
        "href": "/kontakt/#miroslav-kukla"
      }}><strong parentName="a">{`Miroslav Kukla`}</strong></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      